import {get, post, patch} from 'core/configureRequest';

import {getListing} from 'features/listings/actions';
import {get as getProp} from 'utils/helpers';

import {EVENT_CATEGORIES} from 'core/constants';

function formatWebsiteAndRegion(subscription) {
    return `${getProp(subscription, 'package.region.name')}`;
}

export const updatePackageFilter = (filter) => ({
    type: 'UPDATE_VIP_PACKAGE_FILTER',
    filter,
});

const requestVipPackages = () => ({
    type: 'REQUEST_VIP_PACKAGES',
});

const receiveVipPackages = (packages) => ({
    type: 'RECEIVE_VIP_PACKAGES',
    packages,
});

const receiveVipPackagesFailure = () => ({
    type: 'RECEIVE_VIP_PACKAGES_FAILURE',
});

const requestVipSubscriptions = () => ({
    type: 'REQUEST_VIP_SUBSCRIPTIONS',
});

const receiveVipSubscriptionResults = (subscriptions, pagination) => ({
    type: 'RECEIVE_VIP_SUBSCRIPTION_RESULTS',
    subscriptions,
    pagination,
});

const receiveVipSubscriptions = (subscriptions) => ({
    type: 'RECEIVE_VIP_SUBSCRIPTIONS',
    subscriptions,
});

const receiveVipSubscriptionFailure = (e) => ({
    type: 'RECEIVE_VIP_SUBSCRIPTION_FAILURE',
    error: e,
});

const requestCreateVipSubscription = () => ({
    type: 'REQUEST_CREATE_VIP_SUBSCRIPTION',
});

const receiveCreateVipSubscription = (subscription) => ({
    type: 'RECEIVE_CREATE_VIP_SUBSCRIPTION',
    subscription,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.VIP,
            label: formatWebsiteAndRegion(subscription),
        },
    },
});

const requestStartVipSubscription = () => ({
    type: 'REQUEST_START_VIP_SUBSCRIPTION',
});

const receiveStartVipSubscription = (subscription) => ({
    type: 'RECEIVE_START_VIP_SUBSCRIPTION',
    subscription,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.VIP,
            label: formatWebsiteAndRegion(subscription),
        },
    },
});

const requestCancelVipSubscription = () => ({
    type: 'REQUEST_CANCEL_VIP_SUBSCRIPTION',
});

const receiveCancelVipSubscription = (subscription) => ({
    type: 'RECEIVE_CANCEL_VIP_SUBSCRIPTION',
    subscription,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.VIP,
            label: formatWebsiteAndRegion(subscription),
        },
    },
});

const requestEditVipSubscription = () => ({
    type: 'REQUEST_EDIT_VIP_SUBSCRIPTION',
});

const receiveEditVipSubscription = (subscription) => ({
    type: 'RECEIVE_EDIT_VIP_SUBSCRIPTION',
    subscription,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.VIP,
            label: formatWebsiteAndRegion(subscription),
        },
    },
});

const receiveEditVipSubscriptionFailure = () => ({
    type: 'RECEIVE_EDIT_VIP_SUBSCRIPTION_FAILURE',
});

const requestVipInvoices = () => ({
    type: 'REQUEST_VIP_INVOICE_RESULTS',
});

const receiveVipInvoiceResults = (invoices, pagination) => ({
    type: 'RECEIVE_VIP_INVOICE_RESULTS',
    invoices,
    pagination,
});

const receiveVipInvoiceFailure = () => ({
    type: 'RECEIVE_VIP_INVOICE_FAILURE',
});

// code goes here
export const getVipPackages = () => (dispatch, getState) => {
    dispatch(requestVipPackages());

    return get('/proxy/api/vip/package/')
        .then((data) => {
            dispatch(receiveVipPackages(data.data.packages));
            return data;
        })
        .catch((e) => {
            dispatch(receiveVipPackagesFailure(e));

            return Promise.reject(e);
        });
};

export const searchSubscriptions =
    (query = '') =>
    (dispatch, getState) => {
        dispatch(requestVipSubscriptions());

        return get(`/proxy/api/vip/subscription/${query}`)
            .then((data) => {
                const {subscriptions, search_filter, count} = data.data;

                dispatch(
                    receiveVipSubscriptionResults(subscriptions, {
                        ...search_filter,
                        total: count,
                    }),
                );

                return data;
            })
            .catch((e) => {
                dispatch(receiveVipSubscriptionFailure(e));

                return Promise.reject(e);
            });
    };

export const searchInvoices =
    (query = '') =>
    (dispatch, getState) => {
        dispatch(requestVipInvoices());

        return get(`/proxy/api/vip/invoice/${query}`)
            .then((data) => {
                const {invoices, search_filter, count} = data.data;

                dispatch(
                    receiveVipInvoiceResults(invoices, {
                        ...search_filter,
                        total: count,
                    }),
                );

                return data;
            })
            .catch((e) => {
                dispatch(receiveVipInvoiceFailure(e));

                return Promise.reject(e);
            });
    };

export const createVipSubscription = (subscription) => (dispatch, getState) => {
    dispatch(requestCreateVipSubscription());

    return post('/proxy/api/vip/subscription/', subscription)
        .then((data) => {
            dispatch(receiveCreateVipSubscription(data.data.subscription));
        })
        .then(() => dispatch(getVipPackages()))
        .catch((e) => Promise.reject(e));
};

export const getListingSubscriptions = (listingId) => (dispatch, getState) => {
    dispatch(requestVipSubscriptions());

    return get(`/proxy/api/vip/subscription/?listing_id=${listingId}`)
        .then((data) => {
            dispatch(receiveVipSubscriptions(data.data.subscriptions));
        })
        .catch((e) => {
            dispatch(receiveVipSubscriptionFailure(e));

            return Promise.reject(e);
        });
};

export const editVipSubscription = (subscriptionId, values) => (dispatch, getState) => {
    dispatch(requestEditVipSubscription());

    return patch(`/proxy/api/vip/subscription/${subscriptionId}/`, values)
        .then((data) => {
            dispatch(receiveEditVipSubscription(data.data.subscription));
            dispatch(getVipPackages());
            dispatch(getListing(getProp(data, 'data.subscription.listing_id')));
            return data;
        })
        .catch((e) => {
            dispatch(receiveEditVipSubscriptionFailure());
            return Promise.reject(e);
        });
};

export const startVipSubscription = (subscriptionId) => (dispatch, getState) => {
    dispatch(requestStartVipSubscription());

    return patch(`/proxy/api/vip/subscription/${subscriptionId}/`, {status: 'active'})
        .then((data) => {
            dispatch(receiveStartVipSubscription(data.data.subscription));
            dispatch(getVipPackages());
            dispatch(getListing(getProp(data, 'data.subscription.listing_id')));
            return data;
        })
        .catch((e) => {
            dispatch(receiveEditVipSubscriptionFailure());
            return Promise.reject(e);
        });
};

export const cancelVipSubscription = (subscriptionId) => (dispatch, getState) => {
    dispatch(requestCancelVipSubscription());

    return patch(`/proxy/api/vip/subscription/${subscriptionId}/`, {
        status: 'user-canceled',
    })
        .then((data) => {
            dispatch(receiveCancelVipSubscription(data.data.subscription));
            dispatch(getVipPackages());
            dispatch(getListing(getProp(data, 'data.subscription.listing_id')));
            return data;
        })
        .catch((e) => {
            dispatch(receiveEditVipSubscriptionFailure());
            return Promise.reject(e);
        });
};
