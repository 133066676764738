import {get, post, patch, del} from 'core/configureRequest';
import {SubmissionError} from 'redux-form';
import {
    get as getProp,
    queryStringConverter,
    includeChanged,
    omitRecursive,
} from 'utils/helpers';
import {notifyError, notifySuccessWithTimeout} from '../notification/slice';
import {showModal, hideModal} from 'features/modal/slice';
import {EVENT_CATEGORIES} from 'core/constants';

const DEFAULT_ACCOUNT_COUNT = 100;

export const requestAccounts = () => ({
    type: 'REQUEST_ACCOUNTS',
});

export const requestAccount = () => ({
    type: 'REQUEST_ACCOUNT',
});

export const receiveAccountResults = (accounts, pagination) => ({
    type: 'RECEIVE_ACCOUNT_RESULTS',
    accounts,
    pagination,
});

export const mergeAccountResults = (accounts, pagination) => ({
    type: 'MERGE_ACCOUNT_RESULTS',
    accounts,
});

export const receiveAccount = (account) => ({
    type: 'RECEIVE_ACCOUNT',
    account,
});

export const receiveAccountFailure = () => ({
    type: 'RECEIVE_ACCOUNT_FAILURE',
});

export const receiveAccountsFailure = (error) => ({
    type: 'RECEIVE_ACCOUNTS_FAILURE',
    error,
});

export const requestDeleteAccount = () => ({
    type: 'REQUEST_DELETE_ACCOUNT',
});

export const receiveDeleteAccount = (id) => ({
    type: 'RECEIVE_DELETE_ACCOUNT',
    id,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Account,
        },
    },
});

export const updateAccountSearch = (query) => ({
    type: 'UPDATE_ACCOUNT_SEARCH',
    query,
});

export const updateAccountSearchType = (searchType) => ({
    type: 'UPDATE_ACCOUNT_SEARCH_TYPE',
    searchType,
});

export const requestCreateAccount = () => ({
    type: 'REQUEST_CREATE_ACCOUNT',
});

export const receiveCreateAccount = (account) => ({
    type: 'RECEIVE_CREATE_ACCOUNT',
    account,
    meta: {
        analytics: {
            category: EVENT_CATEGORIES.Account,
        },
    },
});

export const searchAccounts = (page, parentAccountId) => (dispatch, getState) => {
    dispatch(requestAccounts());

    const searchParams = {};
    if (parentAccountId) {
        searchParams.parent_id = parentAccountId;
    }

    if (getState().accounts.searchQuery) {
        searchParams.organization_name = getState().accounts.searchQuery;
    }

    if (getState().accounts.searchType) {
        searchParams.account_type = getState().accounts.searchType;
    }

    return get(
        `/proxy/api/account/${queryStringConverter(
            {
                page: page || getProp(getState(), 'accounts.pagination.page') || 1,
                count: DEFAULT_ACCOUNT_COUNT,
                ...searchParams,
            },
            true,
        )}`,
    )
        .then((data) => {
            dispatch(
                receiveAccountResults(data.data.accounts, {
                    page: getProp(data, 'data.page'),
                    count: getProp(data, 'data.count'),
                    total: getProp(data, 'data.total'),
                }),
            );

            data.data.accounts.forEach((account) => {
                // account entity needs to exist on click into that account or it 404's
                if (account?.type === 'marketing') {
                    dispatch(receiveAccount(account));
                }
            });

            return data;
        })
        .catch((e) => {
            dispatch(
                receiveAccountsFailure(
                    e && e.message ? e.message : 'Could not search accounts',
                ),
            );
            return Promise.reject(e);
        });
};

export const searchSubaccounts = (page, parentAccountId) => (dispatch, getState) => {
    dispatch(requestAccounts());

    const searchParams = {};
    searchParams.parent_id = parentAccountId;

    return get(
        `/proxy/api/account/${queryStringConverter(
            {
                page: page || getProp(getState(), 'accounts.pagination.page') || 1,
                count: DEFAULT_ACCOUNT_COUNT,
                ...searchParams,
            },
            true,
        )}`,
    )
        .then((data) => {
            dispatch(
                receiveAccountResults(data.data.accounts, {
                    page: getProp(data, 'data.page'),
                    count: getProp(data, 'data.count'),
                    total: getProp(data, 'data.total'),
                }),
            );

            data.data.accounts.forEach((account) => {
                // account entity needs to exist on click into that account or it 404's
                if (account?.type === 'marketing') {
                    dispatch(receiveAccount(account));
                }
            });

            return data;
        })
        .catch((e) => {
            dispatch(
                receiveAccountsFailure(
                    e && e.message ? e.message : 'Could not search accounts',
                ),
            );
            return Promise.reject(e);
        });
};

export const createAccount = (values) => (dispatch, getState) => {
    dispatch(requestCreateAccount());

    return post('/proxy/api/account/', {
        type: values.type || 'standard',
        ...values,
    })
        .then((data) => {
            dispatch(receiveCreateAccount(data.data.account));

            return Promise.resolve(data.data.account);
        })
        .catch((e) => {
            const error = e && e.message ? e.message : 'Could not create account';
            return Promise.reject(new SubmissionError({_error: error}));
        });
};

export const createSubaccount = (values) => (dispatch, getState) => {
    dispatch(requestCreateAccount());

    return post('/proxy/api/account/', {
        type: values.type || 'subaccount',
        ...values,
    })
        .then((data) => {
            dispatch(receiveCreateAccount(data.data.account));

            return Promise.resolve(data.data.account);
        })
        .catch((e) => {
            const error = e && e.message ? e.message : 'Could not create subaccount';
            return Promise.reject(new SubmissionError({_error: error}));
        });
};

export const getAccount = (id) => (dispatch, getState) => {
    dispatch(requestAccount());

    return get(`/proxy/api/account/${id}/`)
        .then((data) => {
            /**
             * RB Account object has an array of 50,000+ listings,
             * Omit these before saving to state.
             */
            if (data.data.account.type === 'rb-super') {
                const truncatedListings = Object.assign({}, data.data.account, {
                    listings: data.data.account.listings.slice(-9),
                });

                dispatch(receiveAccount(truncatedListings));

                if (data.data.account?.subaccounts) {
                    data.data.account.subaccounts.forEach((subaccount) => {
                        dispatch(receiveAccount(subaccount));
                    });
                }

                return truncatedListings;
            }

            dispatch(receiveAccount(data.data.account));

            return data.data.account;
        })
        .catch(() => dispatch(receiveAccountFailure()));
};

export const getAccountIfNeeded = (id) => (dispatch, getState) => {
    const state = getState();
    const account = state.accounts.entities[id];

    if (account && account.response_format === 'formatDetails') {
        return Promise.resolve(account);
    }

    return dispatch(getAccount(id));
};

export const editAccount = (values) => (dispatch, getState) => {
    const currentValues = getState().accounts.entities[values.account_id];

    const changedValues = includeChanged(values, currentValues);

    /**
     * API currently requires patching of ENTIRE billing_address field
     * if only one field is changed, check if user has edited billing_address
     * and assign those values on top of the existing values
     */

    return patch(`/proxy/api/account/${values.account_id}/`, {
        ...omitRecursive(
            {
                ...changedValues,
                billing_address: values.billing_address,
            },
            'created_at',
            'modified_at',
            'account_id',
            'response_format',
            'cc_ids',
            'balance',
            'outstanding_balance',
        ),
    })
        .then((data) => {
            dispatch(receiveAccount(data.data.account));

            return data;
        })
        .catch((e) => {
            dispatch(notifyError('Could not save account details'));
            return Promise.reject(e);
        });
};

export const deleteAccount = (account) => (dispatch, getState) => {
    dispatch(requestDeleteAccount());

    const promise = new Promise((resolve, reject) => {
        dispatch(
            showModal({
                type: 'DELETE_ACCOUNT',
                props: {
                    message: `Are you sure you want to delete ${account.organization_name}`,
                    loadingText: 'Deleting...',
                    handleClose: () => dispatch(hideModal()),
                    handleConfirm: () => {
                        return del(`/proxy/api/account/${account.account_id}/`)
                            .then((data) => {
                                dispatch(hideModal());
                                dispatch(
                                    notifySuccessWithTimeout(
                                        `${account.organization_name} successfully deleted.`,
                                    ),
                                );
                                dispatch(receiveDeleteAccount(data.data.account_id));

                                return resolve(data);
                            })
                            .catch((e) => {
                                dispatch(notifyError('Could not delete account'));
                                return reject(e);
                            });
                    },
                },
            }),
        );
    });

    return promise;
};
